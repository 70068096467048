import { object } from './_obj.js';
import { loc } from './_location.js';
import { logo } from './_logo.js';
let Block = {
	top: 0,
	show: 0
}
/*
座標
elem.getBoundingClientRect()
*/
let $html = document.documentElement;
let $this;
let logoInfo = {
	sizePC: [160, 112, 444, 312, 3.75],
	sizeSP: [40, 40, 160, 112, 4.44]
}
export class Scroll {
	constructor(){
		$this = this;
		this.topD = 0;
		this.btmD = this.topD + window.innerHeight;
		this.animations = new Array();
		this.blocks 	= new Array();
		this.logoBlock 	= document.querySelector("#logo_pause");
		this.logo 		= document.querySelector("[data-logo]");
		this.logoPath = document.querySelector(".logo a svg#line .svg-elem-2");
		this.headerInner = document.querySelector("header#global_header .header_inner");
		//logo
		this.lottieLoaded = false;
		this.lottie = logo;
		this.body = document.querySelector("body");
		this.lottie.init(document.querySelector(".wrap").getAttribute("data-page")).then( () => {
			//console.log(this.logo)
			$this.lottieLoaded = true;
			$this.checkBlock();
			$this.checkScroll();
			$this.nav();
			setTimeout(function(){
				$this.checkScroll();
			}, 300);
			let timeoutID;
			let timeoutDuration = 200;
			if(document.documentElement.classList.contains("mobile")){
				timeoutDuration = 300;
			}
			document.addEventListener("scroll", ()=>{
				$this.topD = window.pageYOffset;
				//console.log($this.topD);
				if( !$html.classList.contains("scrolling") ){
					$html.classList.add("scrolling");
				}
				clearTimeout(timeoutID);
				$this.checkScroll();
				timeoutID = setTimeout(function(){
					if( $html.classList.contains("scrolling") ){
						$html.classList.remove("scrolling");
					}
				}, timeoutDuration);
			}, false);
		});
	}
	
	checkScroll(){
		if( !this.lottieLoaded ){ return false}
		if( $html.classList.contains("active_article") ){ return false}
		let $h1 = document.querySelector("#global_header h1");
		if( $this.topD <= 0 ){
			$this.topD = 0;
			if(!$html.classList.contains("is_top")){
				$html.classList.add("is_top");
			}
		}else{
			if($html.classList.contains("is_top")){
				$html.classList.remove("is_top");
			}
		}
		//console.log($this.topD);
		$this.btmD = $this.topD + window.innerHeight;
		for(let index in $this.animations){
			if( $this.btmD >= $this.animations[index].top[1] ){
				$this.activeAnimate(index);
			}
		}
		let scaleDistance = 40;
		
		let diff = 46;
		let end  = 128;
		if (window.matchMedia('all and (min-width : 901px)').matches) {
			
		}else{
			diff 	= 96;
			end 	= 136;
		}
		let pausePoint = document.querySelector("footer h4").getBoundingClientRect().top + window.pageYOffset - window.innerHeight + diff;
		let per = ($this.topD)/pausePoint;
		//let startPos = (window.innerHeight - $h1.getBoundingClientRect().top - $h1.clientHeight - ($this.logo.clientHeight - $h1.clientHeight)/2)*-1;
		let startPos = (window.innerHeight - $this.headerInner.getBoundingClientRect().top - ($this.headerInner.clientHeight - $this.logo.clientHeight)/2)*-1;
		let totalH = window.innerHeight - end - $h1.getBoundingClientRect().top - window.pageYOffset - $h1.clientHeight - ($this.headerInner.clientHeight - $this.logo.clientHeight)/2;
		
		//console.log(totalH, startPos, ($this.headerInner.clientHeight - $this.logo.clientHeight)/2)
		per = per < 0 ? 0 : per;
		if($this.topD >= pausePoint){
			per = 1;
			if( !$this.logoBlock.classList.contains("show_logo")){
				$this.logoBlock.classList.add("show_logo");
			}
			scaleLogo("");
			if( !$this.logoBlock.classList.contains("pause") ){
				$this.logoBlock.classList.add("pause");
			}
			$this.logo.style.transform = ``;
		}else{
			if( $this.logoBlock.classList.contains("pause") ){
				$this.logo.classList.add("back");
				
				setTimeout(function(){
					$this.logo.classList.remove("back");
					$this.checkScroll();
				}, 250);
				$this.logoBlock.classList.remove("pause");
				
			}
			if( $this.logoBlock.classList.contains("show_logo") ){
				$this.logoBlock.classList.remove("show_logo");
			}
			let btm = -totalH*(1-per);
			//console.log("logo:",btm, window.innerHeight, logoH);
			if($this.topD <= 0){
				btm = -totalH;
			}
			if(btm < startPos){
				btm = -totalH;
			}
			if(!$this.logo.classList.contains("back")){
				
			}
			$this.logo.style.transform = `translate3d(0, ${ btm }px, 0)`;
			if($this.topD >= pausePoint - scaleDistance && $this.topD < pausePoint){
				if( !$this.logoBlock.classList.contains("show_logo")){
					$this.logoBlock.classList.add("show_logo");
				}
				scaleLogo(btm);
			}else{
				let size = logoInfo.sizeSP;
				if (window.matchMedia('all and (min-width : 901px)').matches) {
					size = logoInfo.sizePC;
				}
				$this.logo.style.width 	= "";
				$this.logo.style.height = "";
				$this.logo.style.left 	= size[4] + "%";
			}
		}
		let frame = Math.floor(90*per);
		
		$this.lottie.target.goToAndStop(frame, true );
		//console.log(offset);
		//$this.logoPath.style = `stroke-dashoffset: ${offset}px; stroke-dasharray: 199px`;
		function scaleLogo(btm){
			let size = logoInfo.sizePC;
			if (window.matchMedia('all and (min-width : 901px)').matches) {
				
			}else{
				size = logoInfo.sizeSP;
			}
			let wDiff 	= size[2] - size[0];
			let hDiff 	= size[3] - size[1];
			let diff 	= ( $this.topD - (pausePoint - scaleDistance) )/scaleDistance;
			if(diff > 1){
				diff = 1;
			}
			
			let w = wDiff*diff + size[0];
			let h = hDiff*diff + size[1];
			let leftDiff = (window.innerWidth - size[2])/2;
			let l = ( leftDiff/window.innerWidth*100 - size[4])*diff + size[4];
			//let tl = (size[2]/2)*-diff;
			$this.logo.style.width 		= w + "px";
			$this.logo.style.height 	= h + "px";
			$this.logo.style.left 		= l + "%";
			$this.logo.style.transform 	= `translate3d(0, ${btm}px, 0)`;
		}
	}
	getDocH(){
		let body = document.body,
			html = document.documentElement;
		
		return height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
	}
	activeAnimate(i){
		let $a = $this.blockA[i];
		if( !$a.classList.contains("animate") ){
			$a.classList.add("animate");
			setTimeout(function(){
				$a.classList.add("animated");
			}, 1000);
		}
	}
	//アニメーションのブロックをチェック
	checkBlock(){
		const Animate = {
			top: []
		}
		$this.blockA = document.querySelectorAll(".a");
		$this.animations = [];
		$this.blockA.forEach( (item) => {
			let _a = object(Animate);
			_a.top = $this.getTop(item, window.innerHeight/3);
			$this.animations.push(_a);
		});
	}
	getTop($dom, offset){
		let t = Math.floor( $dom.getBoundingClientRect().top + window.pageYOffset );
		return [ t, Math.floor( t + offset )]
	}
	
	scrollToPos(posY){
		//console.log("scroll to > ", posY);
		window.scrollTo({
			top: posY,
			behavior: "smooth"
		});
	}
	
	nav(){
		let $logo 	= document.querySelector("[data-logo]");
		let $h1 	= document.querySelector("header h1 a");
		if( $html.classList.contains("mobile") ){
			$h1.removeEventListener("touchstart", $this.hover, { passive: true, capture: false});
			$h1.addEventListener("touchstart", $this.hover, { passive: true, capture: false});
			$h1.removeEventListener("touchend", $this.leave, { passive: true, capture: false});
			$h1.addEventListener("touchend", $this.leave, { passive: true, capture: false});
			
			$logo.removeEventListener("touchstart", $this.hover, { passive: true, capture: false});
			$logo.addEventListener("touchstart", $this.hover, { passive: true, capture: false});
			
			$logo.removeEventListener("touchend", $this.leave, { passive: true, capture: false});
			$logo.addEventListener("touchend", $this.leave, { passive: true, capture: false});
		}else{
			$h1.removeEventListener("mouseenter", $this.hover, { passive: true, capture: false});
			$h1.addEventListener("mouseenter", $this.hover, { passive: true, capture: false});
			$h1.removeEventListener("mouseleave", $this.leave, { passive: true, capture: false});
			$h1.addEventListener("mouseleave", $this.leave, { passive: true, capture: false});
			
			$logo.removeEventListener("mouseenter", $this.hover, { passive: true, capture: false});
			$logo.addEventListener("mouseenter", $this.hover, { passive: true, capture: false});
			$logo.removeEventListener("mouseleave", $this.leave, { passive: true, capture: false});
			$logo.addEventListener("mouseleave", $this.leave, { passive: true, capture: false});
		}
	}
	hover(){
		let $logo = document.querySelector("[data-logo]");
		if( !$logo.classList.contains("hover") ){
			$logo.classList.add("hover");
			let frame = Math.floor(90*1);
			$this.lottie.target.goToAndStop(frame, true );
		}
	}
	leave(){
		//console.log("leave");
		let $logo = document.querySelector("[data-logo]");
		$logo.classList.remove("hover");
		$this.checkScroll();
	}
}