import { object } from './_obj.js';
//import { load } from './_load.js';
import { kvMouse } from './_kv_mouse.js';
let Slide = {
	id: 0,
	isMovie: 0,
	player: "",
	duration: 0
}
let $html = document.documentElement;
export let kv = {
	slides: [],
	current: 0,
	len: 0,
	baseDuration: 8000,
	start: 0,
	end: 0,
	timerId: null,
	isActive: true,
	stopTime: 0,
	canChange: true,
	isSingle: false,
	init: function(){
		//kv.getBackgroundImage();
		let $lists = document.querySelectorAll("[data-slideshow] li");
		kv.len = $lists.length;
		kv.current = 1;
		let $target = document.querySelector("[data-slideshow]");
		$target.querySelector("ul").style.transform = `translate3d(${kv.getPosX()}%, 0, 0)`;
		kv.makeList($lists).then( () => {
			kv.start = kv.getNow();
			kv.goToNext();
			kv.focusEvent();
			kvMouse.init();
			//document.addEventListener('keyup', kv.pause, false);
			//console.log(kv.len)
			if(kv.len === 3){
				$target.classList.add("disable_ui");
				kv.isSingle = true;
			}
			setTimeout(function(){
				kvNav.init();
			}, 1000);
		});
	},
	getBackgroundImage: function(){
		/* Vimeo APIから背景画像を取得
		2023.05.15 カスタムフィールドからPoster画像を取得するように変更
		let vimeos = document.querySelectorAll("[data-movie-id]");
		let count = 0;
		let len = vimeos.length;
		loadPoster();
		
		function loadPoster(){
			let $thisVimeo = vimeos[count];
			let id = $thisVimeo.getAttribute("data-movie-id");
			let url = "https://vimeo.com/api/oembed.json?url=https://vimeo.com/" + id;
			load.getJson(url, {}).then( (res) => {
				//console.log(res);
				//console.log(res, res.thumbnail_url, count, len);
				if(res.thumbnail_url){
					let img = res.thumbnail_url;
					//console.log(img.indexOf("_"))
					//console.log(img);
					if(img.indexOf("_") > -1){
						img = img.split("_")[0];
					}
					$thisVimeo.querySelector(".poster").style.backgroundImage = `url(${img})`;
				}
				count++;
				if(count < len){
					loadPoster();
				}
			});
		}
		*/
	},
	focusEvent: function(){
		window.addEventListener('focus', () => {
			//kv.isActive = true;
			let currentSlide = kv.slides[kv.current];
			kv.stopTime = 0;
		});
		window.addEventListener('blur', () => {
			//kv.isActive = false;
			let currentSlide = kv.slides[kv.current];
			kv.stopTime 	= kv.getNow();
			kvNav.isDragging = false;
			kv.canChange = true;
		});
	},
	makeList: function($lists){
		//console.log("make list")
		return new Promise( (resolve, reject) => {
			kv.slides = [];
			let movies = [];
			$lists.forEach( ($list) =>{
				let isMovie = $list.getAttribute("data-slide");
				if(isMovie === "movie"){
					movies.push(0);
				}
			});
			let count = 0;
			$lists.forEach( ($list, index) =>{
				let _k = object(Slide);
				_k.id = index;
				_k.duration = kv.baseDuration;
				let isMovie = $list.getAttribute("data-slide");
				if(isMovie === "movie"){
					_k.isMovie = true;
					//console.log($list.querySelector("iframe"))
					var options = {
						byline: false, // 投稿者部分の表示
						portrait: false, // 左上のVimeoのマークの表示
						title: false // タイトルの表示
					};
					_k.player = new Vimeo.Player( $list.querySelector("iframe"), options );
					_k.player.setVolume(0);
					//console.log(count, _k.player);
					_k.player.pause();
					_k.player.getDuration().then( (res) => {
						//_k.player.play();
						movies[count] = 1;
					});
					count++;
				}else{
					_k.isMovie = false;
				}
				kv.slides.push(_k);
			});
			checkVideos();
			function checkVideos(){
				let c = 0;
				kv.slides.forEach( (k, idx) => {
					if(k.isMovie){
						if( k.player.getDuration() ){
							movies[c] = 1;
						}
						c++;
					}
				} );
				
				if( movies.includes(0) ){
					setTimeout(function(){
						checkVideos();
					}, 20);
				}else{
					resolve();
				}
			}
		});
		
	},
	dec: function(){
		let currentSlide = kv.slides[kv.current];
		if(kv.canChange){
			kv.canChange = false;
			if(currentSlide.isMovie){
				currentSlide.player.pause().then( ()=> {
					//currentSlide.player.setCurrentTime(0);
				});
			}
			kv.current--;
			kv.goToNext();
		}
	},
	inc: function(){
		let currentSlide = kv.slides[kv.current];
		if(kv.canChange){
			kv.canChange = false;
			if(currentSlide.isMovie){
				currentSlide.player.pause().then( ()=> {
					//currentSlide.player.setCurrentTime(0);
				});
			}
			kv.current++;
			kv.goToNext();
		}
	},
	goToNext: function(){
		kv.start = kv.getNow();
		kv.change().then( () => {
			kv.changeNum();
			kv.startNext();
		});
	},
	changeNum: function(){
		let $prev = document.querySelector("button.btn_prev");
		let $next = document.querySelector("button.btn_next");
		document.querySelector(".progress .current").innerHTML = kv.current;
		document.querySelector(".progress .length").innerHTML = (kv.len - 2);
		let prev = kv.current - 1;
		prev = prev <= 0 ? kv.len-2 : prev;
		let next = kv.current + 1;
		next = next >= kv.len - 1 ? 1 : next;
		//console.log(prev, next);
		//next = 88;
		//prev = 86;
		let nextLen = next.toString().length;
		let prevLen = prev.toString().length;
		
		$prev.querySelector(".inr").className = "inr";
		$next.querySelector(".inr").className = "inr";
		
		$prev.querySelector(".inr").classList.add("l" + prevLen);
		$next.querySelector(".inr").classList.add("l" + nextLen);
		
		
		document.querySelector("button.btn_prev span.prev_num").innerHTML = prev;
		document.querySelector("button.btn_next span.next_num").innerHTML = next;
	},
	startNext: function(){
		let currentSlide = kv.slides[kv.current];
		//console.log(kv.current, kv.slides, currentSlide);
		//console.log(currentSlide);
		kv.end = kv.start + currentSlide.duration;
		//kv.resetMovieCover();
		if(currentSlide.isMovie){
			currentSlide.player.setCurrentTime(0);
			currentSlide.player.getDuration().then( (res) => {
				kv.end = kv.start + res*1000;
				currentSlide.duration = res*1000;
				currentSlide.player.setCurrentTime(0);
				currentSlide.player.ready().then( ()=> {
					currentSlide.player.play();
					kv.resetMovieCover();
					currentSlide.player.on("play", (e) =>{
						let currentList = document.querySelectorAll(`[data-slideshow] ul li`)[kv.current];
						//console.log(currentList)
						let m = currentList.querySelector(".movie");
						if( !m.classList.contains("playing") ){
							m.classList.add("playing");
						}
					});
				});
			});
		}else{
			//kv.end = kv.start + currentSlide.duration;
		}
		kv.timerId = null;
		kv.update();
		setTimeout(function(){
			kv.canChange = true;
		}, 300);
	},
	update: function(){
		kv.check();
		if( !kv.isSingle ){
			kv.timerId = window.requestAnimationFrame(kv.update);
		}
	},
	check: function(){
		let currentSlide = kv.slides[kv.current];
		let current = kv.getNow();
		let stopTime = 0;
		if(!kv.isActive){
			stopTime = current - kv.stopTime;
			kv.start += stopTime;
			kv.stopTime = current;
		}
		let diff 	= kv.start + currentSlide.duration - current
		if(diff <= 0){
			kv.pause();
			kv.start = kv.getNow();
			kv.inc();
		}
		kvMouse.check();
		//document.querySelector("#debug_diff").innerHTML = `${diff} : ${kv.current} / ${kv.len}`;
		//document.querySelector("#debug_kv #canchange span").innerHTML = `${kv.canChange}`;
	},
	change: function(){
		return new Promise( (resolve, reject) => {
			let $target = document.querySelector("[data-slideshow]");
			let w = $target.clientWidth;
			if( $target.classList.contains("notransition") ){
				$target.classList.remove("notransition");
			}
			$target.querySelector("ul").style.transform = `translate3d(${kv.getPosX()}%, 0, 0)`;
			if(kv.current === kv.len - 1 || kv.current === 0){
				kv.canChange = false;
				//console.log("here", kv.current, kv.len, kv.len-1);
				setTimeout(function(){
					$target.classList.add("resetPos");
					if(kv.current >= kv.len - 1){
						kv.current = 1;
					}else if( kv.current === 0){
						kv.current = kv.len - 2;
					}
					setTimeout(function(){
						//console.log("back to start", kv.current);
						$target.querySelector("ul").style.transform = `translate3d(${kv.getPosX()}%, 0, 0)`;
						
						if( $target.classList.contains("moving")){
							$target.classList.remove("moving");
						}
						
						setTimeout(function(){
							$target.classList.remove("resetPos");
							kv.canChange = true;
						}, 20);
						resolve();
					}, 50);
				}, 400);
			}else{
				setTimeout(function(){
					if( $target.classList.contains("moving")){
						$target.classList.remove("moving");
					}
				}, 500)
				resolve();
			}
		});
	},
	getPosX: function(){
		return kv.current*-100;
	},
	getNow: function(){
		let n = new Date();
		return n.getTime();
	},
	pause: function(e){
		window.cancelAnimationFrame(kv.timerId);
	},
	isMobile: function(){
		let isMobile = false;
		if( $html.classList.contains("mobile") ){
			isMobile = true;
		}
		return isMobile;
	},
	resetMovieCover: function(){
		let lists = document.querySelectorAll('[data-slideshow] ul li');
		lists.forEach( ($list, index) => {
			if( $list.getAttribute("data-slide") === "movie"){
				let m = $list.querySelector(".movie");
				if( m.classList.contains("playing") ){
					m.classList.remove("playing");
				}
				if( index != kv.current ){
					//console.log(index);
					kv.slides[index].player.pause().then( () => {
						kv.slides[index].player.setCurrentTime(0);
					});
				}
			}
		});
	}
}
let kvNav = {
	target: null,
	touchArea: null,
	startX: 0,
	srartY: 0,
	moveX: 0,
	isDragging: false,
	currentPosX: 0,
	canChange: true,
	init: function(){
		if( kv.isSingle ){ return false }
		kvNav.touchArea = document.querySelector("[data-slideshow]");
		kvNav.target = kvNav.touchArea.querySelector("ul");
		if('ontouchstart' in window){
			kvNav.touchArea.removeEventListener("touchstart", kvNav.touchStart, { passive: true, capture: false});
			kvNav.touchArea.addEventListener("touchstart", kvNav.touchStart, { passive: true, capture: false});
			
			kvNav.touchArea.removeEventListener("touchmove", kvNav.touchMove, { passive: false, capture: false});
			kvNav.touchArea.addEventListener("touchmove", kvNav.touchMove, { passive: false, capture: false});
			
			kvNav.touchArea.removeEventListener("touchend", kvNav.touchEnd, false);
			kvNav.touchArea.addEventListener("touchend", kvNav.touchEnd, false);
			
			kvNav.touchArea.removeEventListener("touchCancel", kvNav.touchCancel, false);
			kvNav.touchArea.addEventListener("touchCancel", kvNav.touchCancel, false);
		}else{
			kvNav.touchArea.removeEventListener("mousedown", kvNav.touchStart, false);
			kvNav.touchArea.addEventListener("mousedown", kvNav.touchStart, false);
			
			//mouse move event
			kvNav.touchArea.removeEventListener("mousemove", kvMouse.mousemove, false);
			kvNav.touchArea.addEventListener("mousemove", kvMouse.mousemove, false);
			
			kvNav.touchArea.removeEventListener("mouseenter", kvMouse.mouseenter, false);
			kvNav.touchArea.addEventListener("mouseenter", kvMouse.mouseenter, false);
			
			kvNav.touchArea.removeEventListener("mouseleave", kvMouse.mouseleave, false);
			kvNav.touchArea.addEventListener("mouseleave", kvMouse.mouseleave, false);
		}
		//ui
		let $btnPrev = document.querySelector("[data-slideshow] button.btn_prev");
		let $btnNext = document.querySelector("[data-slideshow] button.btn_next");
		
		$btnPrev.removeEventListener("click", kv.dec, false);
		$btnPrev.addEventListener("click", kv.dec, false);
		
		$btnNext.removeEventListener("click", kv.inc, false);
		$btnNext.addEventListener("click", kv.inc, false);
		
	},
	touchStart: function(e){
		//console.log(kv.canChange);
		if(kv.canChange){
			let el = window.getComputedStyle(kvNav.target);
			kvNav.currentPosX = kv.current*-100;
			kvNav.moveX = 0;
			if('ontouchstart' in window){
				kvNav.startX = e.touches[0].pageX;
				kvNav.startY = e.touches[0].pageY;
			}else{
				e.preventDefault();
				kvNav.startX = e.clientX;
				kvNav.touchArea.removeEventListener("mousemove", kvNav.touchMove, false);
				kvNav.touchArea.addEventListener("mousemove", kvNav.touchMove, false);
				
				document.removeEventListener("mouseup", kvNav.touchEnd, false);
				document.addEventListener("mouseup", kvNav.touchEnd, false);
			}
			if(!kvNav.touchArea.classList.contains("touch")){
				kvNav.touchArea.classList.add("touch");
				kvNav.isDragging = true;
			}
		}
	},
	touchMove: function(e){
		//e.preventDefault();
		if(!kv.canChange){ return false; }
		let currentX = 0;
		let currentY = 0;
		let diff = 0;
		let diffY = 0;
		
		if('ontouchstart' in window){
			currentX = e.touches[0].pageX;
			diff = currentX - kvNav.startX;
			diffY = e.touches[0].pageY - kvNav.startY;
			kvNav.moveX = diff;
			let posX = kvNav.currentPosX + diff/document.querySelector("[data-slideshow]").clientWidth*100;
			kvNav.target.style.transform = `translate3d(${posX}%, 0, 0)`;
			//console.log("isSP", diff, posX);
			let touchRate = diffY / diff;
			if( Math.abs(touchRate) > Math.tan(15 * Math.PI/180) ) {
				//スクロール
				if(!kvNav.touchArea.classList.contains("moving")){
					kvNav.touchArea.classList.add("moving");
				}
			}else{
				// スクロールではない
				if(kvNav.touchArea.classList.contains("moving")){
					kvNav.touchArea.classList.remove("moving");
				}
			}
		}else{
			if(kvNav.isDragging){
				currentX = e.clientX;
				diff = currentX - kvNav.startX;
				kvNav.moveX = diff;
				let posX = kvNav.currentPosX + diff/document.querySelector("[data-slideshow]").clientWidth*100;
				
				kvNav.target.style.transform = `translate3d(${posX}%, 0, 0)`;
			}
		}
		
		
		if(kvNav.moveX > 20 || kvNav.moveX < -20){
			if(!kvNav.touchArea.classList.contains("moving")){
				kvNav.touchArea.classList.add("moving");
			}
		}
	},
	touchEnd: function(){
		let limit = 75;
		//console.log(kvNav.moveX);
		if(kvNav.touchArea.classList.contains("touch")){
			kvNav.touchArea.classList.remove("touch");
			kvNav.isDragging = false;
		}
		if(kvNav.moveX >= limit){
			kv.dec();
			kvNav.moveX = 0;
			kvNav.startX = 0;
		}else if(kvNav.moveX <= limit*-1){
			kv.inc();
			kvNav.moveX = 0;
			kvNav.startX = 0;
		}else{
			//kvNav.touchCancel();
			kvNav.canceled();
		}
		kvNav.isDragging = false;
		kvNav.touchArea.removeEventListener("mousemove", kvNav.touchMove, false);
	},
	touchCancel: function(){
		kvNav.canceled();
		//console.log("touch canceled")
	},
	canceled: function(){
		if(kvNav.touchArea.classList.contains("touch")){
			kvNav.touchArea.classList.remove("touch");
			kvNav.isDragging = false;
		}
		kvNav.moveX = 0;
		kvNav.startX = 0;
		kvNav.target.style.transform = `translate3d(${kv.getPosX()}%, 0, 0)`;
		setTimeout(function(){
			/*
			if(!kvNav.touchArea.classList.contains("moving")){
				kvNav.touchArea.classList.add("moving");
			}
			*/
			if(kvNav.touchArea.classList.contains("moving")){
				kvNav.touchArea.classList.remove("moving");
			}
			kvNav.isDragging = false;
			kv.canChange = true;
		}, 500);
	}
}

function matrixToArray(matrix) {
	return matrix.substr(7, matrix.length - 8).split(', ');
}