import { load } from './lib/_load.js';
import { loc } from './lib/_location.js';
import { works, worksFrame } from './_works.js';
let $html 		= document.documentElement;
let post = {
	page: 0,
	maxPage: 0,
	maxPost: 0,
	each: 12,
	category: "",
	reset: function(){
		post.page 		= 0;
		post.category 	= "";
	}
}
export let loadPosts = {
	api: "/wp-json/wp/v2/",
	canChange: true,
	init: function(){
		loadPosts.checkURL();
	},
	start: function(){
		let url = loadPosts.api + "posts";
		let params = {
			per_page: post.each,
			page: post.page + 1
		};
		if(post.category != ""){
			params.categories = loadPosts.getCategoryID();
		}
		load.getWpJson(url, params).then( (res) => {
			//console.log(res);
			post.maxPage = parseInt( res.totalPage );
			post.maxPost = parseInt( res.totalPost );
			//console.log(post);
			loadPosts.checkMore();
			loadPosts.nav();
			template.addPost(res).then( () => {
				let $list = document.querySelector("[data-list-items]");
				let $nav = document.querySelector("nav.nav_categories");
				if( $list.classList.contains("disable") ){
					$list.classList.remove("disable");
				}
				if( $nav.classList.contains("disable") ){
					$nav.classList.remove("disable");
				}
				if(!loadPosts.canChange){
					loadPosts.canChange = true;
				}
				works.nav();
				works.init(post.each, post.page);
			});
		});
	},
	nav: function(){
		let $more = document.querySelector("button[data-btn-more]");
		$more.removeEventListener("click", loadPosts.inc, false);
		$more.addEventListener("click", loadPosts.inc, false);
		//filter
		$filterNav = document.querySelectorAll(".nav_categories ul li a");
		$filterNav.forEach( ($n, index) => {
			if( $html.classList.contains("mobile") ){
				$n.removeEventListener("touchend", loadPosts.getCategory, false);
				$n.addEventListener("touchend", loadPosts.getCategory, false);
			}else{
				$n.removeEventListener("click", loadPosts.getCategory, false);
				$n.addEventListener("click", loadPosts.getCategory, false);
			}
		});
	},
	inc: function(){
		if(loadPosts.canChange){
			loadPosts.canChange = false;
			post.page++;
			if(post.page > post.maxPage - 1){
				post.page = post.maxPage;
			}else{
				loadPosts.loadMore();
			}
			loadPosts.checkMore();
		}
	},
	loadMore: function(){
		let url = loadPosts.api + "posts";
		let params = {
			per_page: post.each,
			page: (post.page + 1)
		};
		if(loadPosts.getCategoryID() != ""){
			params.categories = loadPosts.getCategoryID()
		}
		
		load.getWpJson(url, params).then( (res) => {
			template.addPost(res).then( () => {
				loadPosts.canChange = true;
				works.init(post.each, post.page);
			});
		});
	},
	checkMore: function(){
		let $more = document.querySelector("[data-list-items] .more");
		if(post.page === post.maxPage - 1){
			$more.classList.add("disable");
		}else{
			if( $more.classList.contains("disable") ){
				$more.classList.remove("disable");
			}
		}
	},
	getCategory: function(e){
		e.preventDefault();
		let $btn = this;
		$btn.classList.remove("hover");
		if( $html.classList.contains("scrolling") ){ return false;}
		$filterNav = document.querySelectorAll(".nav_categories ul li a");
		
		let category = this.getAttribute("href");
		//console.log(category);
		if(loadPosts.canChange){
			loadPosts.canChange = false;
			if(category === "?category=all"){
				category = "/";
				$filterNav = document.querySelectorAll(".nav_categories ul li a");
				$filterNav.forEach( ($f, index) => {
					if( $f.classList.contains("current") ){
						$f.classList.remove("current");
					}
				});
				$filterNav[0].classList.add("current");
			}else{
				post.page = 0;
				$filterNav.forEach( ($f, index) => {
					if( $f.classList.contains("current") ){
						$f.classList.remove("current");
					}
				});
				$btn.classList.add("current");
			}
			window.history.pushState(null, null, category);
			loadPosts.checkURL();
		}
	},
	checkURL: function(){
		let params = loc.all();
		if( params.params.category){
			post.category = params.params.category;
		}else{
			post.category = "";
		}
		let $list = document.querySelector("[data-list-items]");
		let $nav = document.querySelector("nav.nav_categories");
		$list.classList.add("disable");
		$nav.classList.add("disable");
		
		setTimeout(function(){
			loadPosts.start();
		}, 200);
		//console.log("category:", post.category);
	},
	getCategoryID: function(){
		$filterNav = document.querySelectorAll(".nav_categories ul li a");
		let id = "";
		$filterNav.forEach( ($f) => {
			let thisCat = $f.getAttribute("data-category");
			//console.log(thisCat, post.category);
			if( thisCat === post.category){
				id = $f.getAttribute("data-id");
				$f.classList.add("current");
			}else if(post.category === "" && !thisCat){
				$f.classList.add("current");
			}else{
				if( $f.classList.contains("current") ){
					$f.classList.remove("current");
				}
			}
		});
		return id;
	}
}

let template = {
	addPost: function(res){
		return new Promise((resolve, reject) => {
			let $lists = document.querySelector("[data-list-items] ul");
			let postHTML = "";
			let start 	= post.page*post.each;
			let end 	= start + post.each;
			let len = end;
			len = len >= post.maxPost ? post.maxPost : len;
			//console.log(start, end, len)
			let count = 0;
			for(let i = start; i < len; i++){
				postHTML += template.make( count, res.data[count] );
				count++;
			}
			
			if(post.page === 0){
				//$lists.innerHTML = "";
				$lists.innerHTML = postHTML;
				if( $lists.classList.contains("loaded_more") ){
					$lists.classList.remove("loaded_more");
				}
			}else{
				$lists.insertAdjacentHTML("beforeend", postHTML);
				if( !$lists.classList.contains("loaded_more") ){
					$lists.classList.add("loaded_more");
				}
			}
			resolve();
		});
	},
	make: function(i, data){
		let d = data;
		//console.log(i, d);
		let HTML = ``,
			imgs = ``;
		let domain = window.location.protocol + "//" + window.location.hostname;
		let needReplace = false;
		//URLをルートパスにしないとiPhoneで画像が表示されない問題アリ < WPで対応
		//console.log(d);
		for(let j = 0; j < d.thumbnails.length; j++){
			let t = d.thumbnails[j];
			let sizes = t.sizes;
			//console.log(t);
			//console.log(sizes);
			let cls = "sq";
			if( sizes["medium-width"] > sizes["medium-height"] ){
				cls = "h";
			}else if( sizes["medium-width"] < sizes["medium-height"] ){
				cls = "v";
			}
			if(j === 0){
				cls += " active";
			}
			let imgpath = sizes["medium"].replace(domain, "");
			
			imgs 	+= `<li class="${cls}">
							<figure>
								<img src="${imgpath}" alt="${d.title}" width="${sizes["medium-width"]}" height="${sizes["medium-height"]}">
							</figure>
						</li>`;
		}
		
		let permalink = d.link.replace(domain, "");
		HTML = `<li class="item loaded">
					<a href="${permalink}" data-name="${d.title}" data-color="${d.color}">
						<ul class="imgs">${imgs}</ul>
						<div class="hover_sp" style="background-color: ${d.color}">
							<div class="inner"><p>View</p></div>
						</div>
					</a>
				</li>`;
		
		return HTML;
	}
}