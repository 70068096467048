export const load = {
	getJson: function(url, params){
		let getURL = url + "?" + new URLSearchParams(params);
		return fetch(getURL).then( function(res){
			return res.json();
		}).then(function(data){
			return data;
		})
	},
	getWpJson: function(url, params){
		let getURL = url + "?" + new URLSearchParams(params);
		console.log(getURL);
		let returnData = {
			data: [],
			totalPage: 0,
			totalPost: 0
		};
		return fetch(getURL).then( function(res){
			returnData.totalPage = res.headers.get("x-wp-totalpages");
			returnData.totalPost = res.headers.get("X-WP-Total");
			return res.json();
		}).then(function(data){
			returnData.data = data;
			return returnData;
		});
	},
	postJson: function(url, param){
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: param
		}
		return fetch(url, options).then( function(res){
			return res.json();
		}).then(function(data){
			return data;
		});
	}
}