import { load } from './_load.js';
export let wheather = {
	target: "",
	HTML: "",
	timezones: [],
	count: 0,
	timer: null,
	slideTimer: null,
	colors: [0, 5, 10, 16, 18, 24],
	isSummerTime: false,
	pos: {
		target: "",
		targets: "",
		current: 0,
		max: 0
	},
	init: function(){
		//this.apiLoad();
		wheather.target = document.querySelector("#wheather ul");
		return new Promise((resolve, reject) => {
			let url = "/wp-content/themes/cekai_theme/assets/json/timezone.json";
			load.getJson(url).then( (res)=> {
				let len = res.cities.length;
				for(let i = 0; i < len; i++){
					wheather.makeHTML(res.cities[i], i);
					wheather.timezones.push(res.cities[i].timezone);
				}
				wheather.addHTML();
			});
		});
		//wheather.apiLoad();
	},
	makeHTML: function(data, i){
		let emoji 	= "";
		if(data.name === "Los Angeles"){
			emoji = "🎡";
			//city = "losangeles";
		}else if(data.name === "Tokyo"){
			emoji = "🗼";
			//city = "tokyo";
		}else if(data.name === "New York"){
			emoji = "🗽";
			//city = "newyork";
		}
		let result = this.getDate(data.timezone);
		let now = result.dates;
		let colorCls = "c" + result.color;
		//wheather.HTML += `<li class="${colorCls}"><a href="https://www.google.com/maps/search/?api=1&query=${data.name}" target="_blank" rel="noopener"><span>${emoji} <span class="time${i}">${now}</span></span></a></li>`;
		wheather.HTML += `<li class="${colorCls}">
							<a href="https://www.google.com/maps/search/?api=1&query=${data.name}" target="_blank" rel="noopener">
								<div class="inr"><i>${emoji}</i> <span class="time${i} flex">${now}</span></div>
							</a>
						</li>`;
		//wheather.HTML += `<li class="${colorCls}"><a href="https://www.google.com/maps/search/?api=1&query=${data.name}" target="_blank" rel="noopener"><span>${emoji} <span class="name">${data.name},</span> <span class="time${i}">${now}</span></span></a></li>`;
	},
	addHTML: function(){
		//画面サイズ取得していくつ配置するか
		wheather.target.innerHTML = wheather.HTML;
		wheather.pos.targets = wheather.target.querySelectorAll("li");
		
		let listW = wheather.getTotalW();
		let n = Math.ceil(window.innerWidth/listW) + 1;
		for(let i = 0; i < n; i++){
			wheather.HTML += wheather.HTML;
		}
		wheather.target.innerHTML = wheather.HTML;
		wheather.pos.targets = wheather.target.querySelectorAll("li");
		wheather.timer 		= setInterval(wheather.clock, 1000);
		wheather.update();
	},
	update: function(){
		wheather.pos.max = 0;
		for(let i = 0; i < wheather.timezones.length; i++){
			wheather.pos.max += wheather.pos.targets[i].clientWidth;
		}
		wheather.pos.current -= .5;
		wheather.target.style.transform = `translate3d(${wheather.pos.current}px, 0, 0)`;
		wheather.slideTimer = requestAnimationFrame(wheather.update);
		//console.log(wheather.pos.max, wheather.pos.current);
		if(wheather.pos.current <= -wheather.pos.max){
			wheather.pos.current = 0;
		}
	},
	clock: function(){
		let w = wheather;
		for(let i = 0; i < w.timezones.length; i++){
			let timezone = w.timezones[i];
			let result = w.getDate(timezone);
			let now 	= result.dates;
			let colorCls = "c" + result.color;
			let doms 	= document.querySelectorAll(`.time${i}`);
			
			doms.forEach( ($t) => {
				$t.innerHTML = now;
				let $parent = $t.closest("li");
				if( !$parent.classList.contains(colorCls) ){
					$parent.className = "";
					$parent.classList.add(colorCls);
				}
			});
		}
	},
	getDate: function(timezone){
		let result = {
			dates: "",
			color: ""
		}
		let week 	= ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
		let months 	= ['Jan.','Feb.','Mar.','Apr.','May','June','July','Aug.','Sept.','Oct.','Nov.','Dec.'];
		let now = new Date(Date.now() + ((new Date().getTimezoneOffset() + (timezone/60)) * 60 * 1000));
		if(timezone != 32400){
			wheather.checkSummerTime(now);
			if(wheather.isSummerTime){
				now = new Date(Date.now() + ((new Date().getTimezoneOffset() + (timezone/60)) * 60 * 1000) + 1000*60*60);
			}
		}
		result.dates = `<span class="l">${week[now.getDay()]}. ${months[now.getMonth()]}. ${ wheather.getSuffix(now.getDate()) },</span> <span class="r">${now.getFullYear()}, ${wheather.getTime(now)}</span>`;
		result.color = wheather.getColor(now);
		return result;
	},
	getSuffix: function(n){
		var result;
		var s1 = +('' + n).slice(-1);
		var s2 = +('' + n).slice(-2);
		if (s2 >= 11 && s2 <= 13) {
			result = n + 'th';
		} else if (s1 === 1) {
			result = n + 'st';
		} else if (s1 === 2) {
			result = n + 'nd';
		} else if (s1 === 3) {
			result = n + 'rd';
		} else {
			result = n + 'th';
		}
		return result;
	},
	getTime: function(now){
		let hour 	= now.getHours();
		let h = hour%12;
		let suffix = "";
		if(hour < 12){
			suffix = "am";
		}else{
			suffix = "pm";
		}
		let min 	= now.getMinutes();
		let sec 	= now.getSeconds();
		return h + ":" + ( '00' + min ).slice( -2 ) + ":" + ( '00' + sec ).slice( -2 ) + suffix;
	},
	getTotalW: function(){
		let totalW = 0;
		for(let i = 0; i < wheather.timezones.length; i++){
			totalW += wheather.pos.targets[i].clientWidth;
		}
		return totalW;
	},
	getColor: function(now){
		let hour 	= now.getHours();
		let num = 0;
		for(let i = 0; i < wheather.colors.length; i++){
			let c = wheather.colors[i];
			//console.log(c, hour);
			if(i === wheather.colors.length - 1){
				if( hour >= c){
					num = wheather.colors.length - 1;
					num = i;
				}
			}else{
				if( hour >= c && hour < wheather.colors[i+1] ){
					num = i;
				}
			}
		}
		return num;
	},
	checkSummerTime: function(now){
		/* 
			サマータイム期間: 3月 第2日曜日 午前2時（夏時間午前3時） ～ 11月第1日曜日 午前2時（夏時間午前3時）
		*/
		const startM = 3,
			startWeek = 2,//第二週目
			startTime = '02:00',
			endM = 11,
			endWeek = 1,//第一週目
			endTime = '02:00';
		var getTheDay = function(y, m, n){
			let first = new Date(y, (m - 1)),
			firstDay = first.getDay(),
			sun;
			if(firstDay === 0){
				sun = 1;
			}else{
				sun = 8 - firstDay;
			}
			sun += (n - 1)*7;
			return sun
		}
		let start = getTheDay( now.getFullYear(), startM, startWeek );
		start = new Date( now.getFullYear() + "/" + startM + "/" + start + " " + startTime).getTime();
		
		let end = getTheDay( now.getFullYear(), endM, endWeek );
		end = new Date( now.getFullYear() + "/" + endM + "/" + end + " " + endTime ).getTime();
		
		if( now.getTime() > start && now.getTime() < end ){
			wheather.isSummerTime = true;
		}
	}
}