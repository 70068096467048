import { load } from './_load.js';
export let logo = {
	target: "",
	init: function(page){
		return new Promise((resolve, reject) => {
			let dataURL = "/wp-content/themes/cekai_theme/assets/json/lottie.json"
			if(page === "about"){
				let n = Math.floor( Math.random()*4 );
				let arry = ["a", "b", "c", "d"];
				document.querySelector("body").classList.add(`color${n}`);
				let $idImg = document.querySelector("[data-cekai-id] #bg_symbol img");
				$idImg.setAttribute("src", "/wp-content/themes/cekai_theme/assets/images/common/symbol/sym_" + arry[n] + ".svg");
				dataURL = `/wp-content/themes/cekai_theme/assets/json/about/${n}/data.json`;
				//console.log(dataURL);
			}
			let data = {};
			load.getJson( dataURL, {} ).then( (res) => {
				data = res;
				//console.log(data);
				logo.target = lottie.loadAnimation({
					container: document.getElementById('logo_lottie'), 
					renderer: 'svg', // 描画形式
					loop: false, // trueにしたらループ。1回再生の場合はfalse
					autoplay: false, // 自動再生
					animationData: res,
					//path: "/assets/json/data.json", // jsonのパスを指定
					name: "lottie_logo",
					rendererSettings: {
						id: "logo"
					}
				});
				logo.target.addEventListener("DOMLoaded", function(){
					resolve();
				}, false)
			});
		});
	}
}