import { Scroll } from './lib/_scroll.js';
let $html = document.documentElement;
export var common = {
	scrl: "",
	beforeW: 0,
	beforeType: "",
	init: ()=>{
		common.start();
	},
	start: function(){
		let resizeFlag;
		let resizeTimer = null;
		let $body = document.getElementsByTagName('body')[0];
		
		common.nav();
		common.scrl = new Scroll();
		common.resize();
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		window.addEventListener("resize", function(){
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		}, false);
	},
	nav: ()=>{
		let $scrolls = document.querySelectorAll("[data-scroll]");
		$scrolls.forEach( ($s, index) => {
			$s.removeEventListener("click", common.scrlToPos, { passive: false, capture: false});
			$s.addEventListener("click", common.scrlToPos, { passive: false, capture: false});
		});
		
		let $mails = document.querySelectorAll("[data-mail]");
		$mails.forEach( ($mail) => {
			if( $html.classList.contains("mobile") ){
				$mail.removeEventListener("touchend", common.mailTo, { passive: true, capture: false});
				$mail.addEventListener("touchend", common.mailTo, { passive: true, capture: false});
			}else{
				$mail.removeEventListener("click", common.mailTo, { passive: true, capture: false});
				$mail.addEventListener("click", common.mailTo, { passive: true, capture: false});
			}
		});
	},
	mailTo: function(){
		if( !$html.classList.contains("scrolling") ){
			let toAddress 	= document.querySelector(".footer_content [data-mail]").getAttribute("data-mail");
			let subject 	= "";
			let body 		= "";
			location.href = 'mailto:' + toAddress + '?subject=' + subject + '&body=' + body;
		}
	},
	scrlToPos: function(e){
		e.preventDefault();
		window.scroll({
			top: 0,
			behavior: "smooth"
		});
		common.removeSymbolHover();
	},
	removeSymbolHover: function(){
		let symbols = document.querySelectorAll("[data-cekai-id]");
		symbols.forEach( ($symbol) =>{
			if( $symbol.classList.contains("hover") ){
				$symbol.classList.remove("hover");
			}
		});
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let $body 	= document.getElementsByTagName('body')[0];
		
		
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
			common.scrl.checkScroll();
		},300);
		common.checkVideo();
		common.getScrollBar();
		common.scrl.checkScroll();
		common.beforeW = winW;
	},
	checkVideo: function(){
		let $videos = document.querySelectorAll("article video")
		let switchW = 901;
		let currentType = "";
		if (window.matchMedia(`all and (min-width : ${switchW}px)`).matches) {
			currentType = "pc";
		}else{
			currentType = "sp";
		}
		if(currentType != common.beforeType){
			$videos.forEach( ($video, index) => {
				let $pcSrc = $video.querySelector(".pc_movie");
				let $spSrc = $video.querySelector(".sp_movie");
				let pcMovie = $pcSrc.getAttribute("data-src");
				let spMovie = $spSrc.getAttribute("data-src");
				if (window.matchMedia(`all and (min-width : ${switchW}px)`).matches) {
					common.beforeType = "pc";
					//PC
					$pcSrc.setAttribute("src", pcMovie);
					$spSrc.setAttribute("src", "");
				}else{
					//SP
					common.beforeType = "sp";
					let movie = spMovie;
					if(spMovie === ""){
						movie = pcMovie;
					}
					$spSrc.setAttribute("src", movie);
					$pcSrc.setAttribute("src", "");
				}
				$video.muted = true;
				$video.load();
				$video.addEventListener('loadedmetadata', function() {
					console.log("checkVideo:", spMovie, "metaloaded");
					$video.play();
				});
			});
		}
	},
	removeMultipleClass: function(dom, className){
		const target = document.querySelector(dom);
		const targetClassList = target.className;
		const myRegExp = new RegExp("/\b" + className + "\S+/", 'g');
		const myMatched = targetClassList.match(myRegExp) || [];
		for(let n = 0; n < myMatched.length; n++) {
		  target.classList.remove(myMatched[n]);
		}
	},
	getScrollBar: function(){
		const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
		// カスタムプロパティの値を更新する
		document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
	}
}
