export let kvMouse = {
	target: null,
	isEnter: false,
	isStay: false,
	pos: [0, 0],
	beforePos: [0, 0],
	startTime: 0,
	count: 0,
	endTime: 2000,
	init: function(){
		kvMouse.target = document.querySelector("[data-slideshow]");
		kvMouse.pos 		= [0, 0];
		kvMouse.beforePos 	= [0, 0];
		kvMouse.count 		= 0;
	},
	mouseenter: function(){
		if( !kvMouse.isEnter ){
			kvMouse.isEnter = true;
		}
	},
	mouseleave: function(){
		if( kvMouse.isEnter ){
			kvMouse.isEnter = false;
		}
	},
	mousemove: function(e){
		let km = kvMouse;
		km.pos[0] = e.clientX;
		km.pos[1] = e.clientY;
	},
	check: function(e){
		if( kvMouse.isEnter ){
			if(kvMouse.beforePos[0] === kvMouse.pos[0] && kvMouse.beforePos[1] === kvMouse.pos[1]){
				if( !kvMouse.isStay ){
					kvMouse.startTime = kvMouse.getTime();
				}
				kvMouse.isStay = true;
				if(kvMouse.getDiff() >= kvMouse.endTime){
					if( !kvMouse.target.classList.contains("hide") ){
						kvMouse.target.classList.add("hide");
					}
				}
				//console.log(kvMouse.count);
			}else{
				kvMouse.isStay 	= false;
				kvMouse.count 	= 0;
				if( kvMouse.target.classList.contains("hide") ){
					kvMouse.target.classList.remove("hide");
				}
			}
			//console.log(kvMouse.isStay);
		}
		kvMouse.beforePos[0] = kvMouse.pos[0];
		kvMouse.beforePos[1] = kvMouse.pos[1];
	},
	getTime: function(){
		let d = new Date();
		return d.getTime();
	},
	getDiff: function(){
		let n = kvMouse.getTime();
		let diff = n - kvMouse.startTime;
		return diff;
	}
}