import { common } from './../_common.js';
let $html = document.documentElement;
export let symbol = {
	target: "",
	isPage: "",
	count: 0,
	current: 0,
	max: 0,
	isHover: false,
	timer: null,
	init: function(is_page){
		let s = symbol;
		//console.log(s.isPage);
		s.count = 0;
		s.current = 0;
		if(is_page === "top" || is_page === "about"){
			s.target = document.querySelector("#global_footer [data-cekai-id]");
		}else if(is_page === "workdetail"){
			s.target = document.querySelector("#article_footer [data-cekai-id]");
		}
		
		s.lists = s.target.querySelectorAll("ul li");
		s.link = s.target.querySelector("a");
		
		s.max = s.lists.length;
		s.change();
		if( $html.classList.contains("mobile") ){
			s.link.removeEventListener("touchstart", s.hover, { passive: true, capture: false});
			s.link.addEventListener("touchstart", s.hover, { passive: true, capture: false});
			s.link.removeEventListener("touchend", s.leave, { passive: true, capture: false});
			s.link.addEventListener("touchend", s.leave, { passive: true, capture: false});
		}else{
			s.link.removeEventListener("mouseenter", s.hover, { passive: true, capture: false});
			s.link.addEventListener("mouseenter", s.hover, { passive: true, capture: false});
			s.link.removeEventListener("mouseleave", s.leave, { passive: true, capture: false});
			s.link.addEventListener("mouseleave", s.leave, { passive: true, capture: false});
		}
		
		clearInterval(s.timer);
		s.timer = setInterval(s.update, 1000);
		common.nav();
	},
	hover: function(){
		if(!symbol.isHover){
			symbol.isHover = true;
			symbol.target.classList.add("hover");
		}
	},
	leave: function(){
		if(symbol.isHover){
			symbol.isHover = false;
			symbol.target.classList.remove("hover");
		}
	},
	update: function(){
		let s = symbol;
		if(!s.isHover){
			s.count++;
			s.current = s.count%s.max;
			symbol.change();
		}
	},
	change: function(){
		let s = symbol;
		s.lists.forEach( ($list, index) => {
			if( index === s.current ){
				if( !$list.classList.contains("active") ){
					$list.classList.add("active");
				}
			}else{
				if( $list.classList.contains("active") ){
					$list.classList.remove("active");
				}
			}
		});
	}
}