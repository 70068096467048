import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { symbol } from './lib/_symbol.js';
import { wheather } from './lib/_wheather.js';
import { kv } from './lib/_kv_slideshow.js';
import { common } from './_common.js';
import { loadPosts } from './_loadPosts.js';
import { worksMovies } from './lib/_works_movies.js';

let $this;
let $html 		= document.documentElement;
export class Framework {
	constructor(){
		$this = this;
		this.firstVisit = true;
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
		//console.log(this.pageinfo);
	}
	
	checkURL(){
		this.pageinfo = loc.all();
		//path
		this.afterChangePage();
	}
	
	afterChangePage(){
		if(this.firstVisit){
			this.firstVisit = false;
			common.init();
		}
		let folder = $this.pageinfo.pathArry[1];
		//console.log("folder:", folder);
		if( $this.pageinfo.pathLen >= 4 && $this.pageinfo.pathArry[ $this.pageinfo.pathLen-1] === ""){ //works 詳細ページ
			loadPosts.init();
			symbol.init("workdetail");
			worksMovies.init();
		}else{
			if( folder === "about" ){
				symbol.init("about");
			}else if( folder === "" ){ //トップ
				loadPosts.init();
				kv.init();
				symbol.init("top");
			}
		}
		wheather.init();
		setTimeout(function(){
			document.querySelector("body").classList.remove("disable");
		}, 200);
		common.resize();
		common.nav();
		this.nav();
	}
	nav(){
		//スマホ用hover
		if( $html.classList.contains("mobile") ){
			let $hovers = document.querySelectorAll("[data-hover]");
			$hovers.forEach( ($hover, index) => {
				$hover.removeEventListener("touchstart", $this.hover, { passive: true, capture: false});
				$hover.addEventListener("touchstart", $this.hover, { passive: true, capture: false});
				$hover.removeEventListener("touchend", $this.leave, { passive: true, capture: false});
				$hover.addEventListener("touchend", $this.leave, { passive: true, capture: false});
				//
				$hover.removeEventListener("touchcancel", $this.leave, { passive: true, capture: false});
				$hover.addEventListener("touchcancel", $this.leave, { passive: true, capture: false});
			});
			let $navs = document.querySelectorAll("#global_header nav li a");
			$navs.forEach( ($nav) => {
				$nav.removeEventListener("touchstart", $this.hover, { passive: false, capture: false});
				$nav.addEventListener("touchstart", $this.hover, { passive: false, capture: false});
				$nav.removeEventListener("touchend", $this.leaveLink, { passive: false, capture: false});
				$nav.addEventListener("touchend", $this.leaveLink, { passive: false, capture: false});
				//
				$nav.removeEventListener("touchcancel", $this.leave, { passive: false, capture: false});
				$nav.addEventListener("touchcancel", $this.leave, { passive: false, capture: false});
			});
		}
	}
	hover(e){
		e.preventDefault();
		let $btn = this;
		if( !$btn.classList.contains("hover") ){
			$btn.classList.add("hover");
		}
	}
	leave(){
		let $btn = this;
		if( $btn.classList.contains("hover") ){
			$btn.classList.remove("hover");
		}
	}
	leaveLink(){
		let $btn = this;
		let url = this.getAttribute("href");
		location.href = url;
		if( $btn.classList.contains("hover") ){
			$btn.classList.remove("hover");
		}
	}
	checkWorksFvVideo(){
		let type = document.querySelector("#kv_project").getAttribute("data-type");
		//console.log(type);
		if(type === "video"){
			
		}
	}
}