import lozad from 'lozad';
import { common } from './_common.js';
import { symbol } from './lib/_symbol.js';
import { kv } from './lib/_kv_slideshow.js';
import { worksSlide } from './lib/_works_slideshow.js';
import { loc } from './lib/_location.js';
import { preload } from './lib/_preload.js';
import { worksMovies } from './lib/_works_movies.js';
let $html = document.documentElement;
let worksTap = {
	startTime: 0,
	start: function(){
		worksTap.startTime = worksTap.getNow();
		//console.log(startTime);
	},
	getNow: function(){
		let now = new Date();
		return now.getTime();
	}
}
export let works = {
	hoverView: null,
	openedPosition: 0,
	isSafari: false,
	hoverInfo: {
		target: null,
		left: 0,
		cols: 2,
		lefts: []
	},
	scrollTop: 0,
	isClicked: false,
	init: function(){
		works.hoverView = document.querySelector("[data-block-view]");
		if( $html.classList.contains("safari") && $html.classList.contains("pc") ){
			works.isSafari = true;
		}
		works.nav();
		worksSlide.init();
		worksFrame.detailNav();
		window.addEventListener('popstate', function(e) {
			e.preventDefault();
			let link = location.pathname;
			worksFrame.changeURL(link);
		});
	},
	nav: function(){
		let $kvLinks = document.querySelectorAll("[data-slideshow] li a");
		let $lists = document.querySelectorAll("[data-list-items] ul li.item a");
		
		$lists.forEach( ( $list, index ) => {
			if( $html.classList.contains("mobile") ){
				$list.removeEventListener("touchstart", works.hover, { passive: true, capture: false});
				$list.addEventListener("touchstart", works.hover, { passive: true, capture: false});
				$list.removeEventListener("touchend", worksFrame.getURL, { passive: false, capture: false});
				$list.addEventListener("touchend", worksFrame.getURL, { passive: false, capture: false});
				
				$list.removeEventListener("touchcancel", works.leave, { passive: true, capture: false});
				$list.addEventListener("touchcancel", works.leave, { passive: true, capture: false});
			}else{
				$list.removeEventListener("mouseenter", works.hover, false);
				$list.addEventListener("mouseenter", works.hover, false);
				$list.removeEventListener("mouseleave", works.leave, false);
				$list.addEventListener("mouseleave", works.leave, false);
				$list.removeEventListener("click", worksFrame.getURL, false);
				$list.addEventListener("click", worksFrame.getURL, false);
			}
		});
		//Related Project
		let $relatedLists = document.querySelectorAll("[data-list-related] ul li.item a");
		$relatedLists.forEach( ( $list ) => {
			if( $html.classList.contains("mobile") ){
				$list.removeEventListener("touchstart", works.hover, { passive: true, capture: false});
				$list.addEventListener("touchstart", works.hover, { passive: true, capture: false});
				
				$list.removeEventListener("touchend", worksFrame.getURL, { passive: false, capture: false});
				$list.addEventListener("touchend", worksFrame.getURL, { passive: false, capture: false});
				
				$list.removeEventListener("touchcancel", works.leave, { passive: true, capture: false});
				$list.addEventListener("touchcancel", works.leave, { passive: true, capture: false});
			}else{
				$list.removeEventListener("mouseenter", works.hover, false);
				$list.addEventListener("mouseenter", works.hover, false);
				$list.removeEventListener("mouseleave", works.leave, false);
				$list.addEventListener("mouseleave", works.leave, false);
				
				$list.removeEventListener("click", worksFrame.getURL, false);
				$list.addEventListener("click", worksFrame.getURL, false);
			}
		});
		
		$kvLinks.forEach( ($link ) => {
			if( $html.classList.contains("mobile") ){
				$link.removeEventListener("touchend", worksFrame.getSlideshowURL, false);
				$link.addEventListener("touchend", worksFrame.getSlideshowURL, false);
			}else{
				$link.removeEventListener("click", worksFrame.getSlideshowURL, false);
				$link.addEventListener("click", worksFrame.getSlideshowURL, false);
			}
		});
	},
	hover: function(){
		let $this 	= this;
		let parent 	= this.parentNode;
		
		worksSlide.isHover = true; //スライドショーを止める
		worksTap.startTime = worksTap.getNow();
		works.hoverInfo.this 	= this;
		works.hoverInfo.left 	= parent.getBoundingClientRect().left;
		works.hoverInfo.lefts = [];
		let allList = document.querySelectorAll("[data-list-items] ul li.item");
		if( $html.classList.contains("show_works") ){
			allList = document.querySelectorAll("[data-list-related] ul li.item");
		}
		let lists = [].slice.call( allList );
		
		let thisIndex = lists.indexOf(parent);
		works.hover.cols = 2;
		if (window.matchMedia('all and (min-width : 1921px)').matches) {
			works.hoverInfo.cols = 5;
		}else if (window.matchMedia('all and (min-width : 1521px)').matches) {
			works.hoverInfo.cols = 4;
		}else if (window.matchMedia('all and (min-width : 901px)').matches) {
			works.hoverInfo.cols = 3;
		}
		let grid = {
			colNum: works.hoverInfo.cols,
			row: Math.floor( thisIndex/works.hoverInfo.cols ),
			current: thisIndex
		}
		
		if ( window.matchMedia('all and (min-width : 901px)').matches ) {
			//viewBoxに画像を入れる
			works.cloneText( $this.getAttribute("data-name") );
			works.copyImages( $this, grid );
			let posY = works.getBlockPos( $this.parentNode );
			works.hoverView.style.top = posY + "px";
			if( !$this.classList.contains("hover") ){
				$this.classList.add("hover");
				setTimeout(function(){
					works.hoverView.classList.add("active");
				}, 50);
				let hoverImgs = document.querySelectorAll("[data-block-view] .view_imgs ul li");
				setTimeout(function(){
					hoverImgs.forEach( ($li) => {
						$li.style.transform = `translate3d(0px, 0, 0)`;
					});
				}, 10);
			}
		}else{
			if( !$this.classList.contains("hover") ){
				works.scrollTop = window.pageYOffset;
				$this.classList.add("hover");
			}
		}
	},
	leave: function(){
		let $this = this;
		//console.log("leave");
		if( $this.classList.contains("hover") ){
			$this.classList.remove("hover");
			works.hoverView.classList.remove("active");
			works.returnHideImage();
			//works.hoverView.style.top = "";
			worksSlide.isHover = false;
		}
	},
	returnHideImage(){
		if(works.isClicked){ return false}
		let lists = document.querySelectorAll("[data-list-items] ul li.item.hide_img");
		if( $html.classList.contains("show_works") ){
			lists = document.querySelectorAll("[data-list-related] ul li.item");
		}
		//console.log("return hide image");
		lists.forEach( ($li) => {
			if( $li.classList.contains("hide_img") ){
				$li.classList.remove("hide_img");
			}
		});
	},
	makeImageArray: function(grid, maxCol, $lists){
		let imgLists = [];
		let len 	= $lists.length;
		if($lists.length === 1){
			for(let i = 0; i < maxCol; i++){
				imgLists.push( $lists[0] );
			}
		}else{
			//スライドショーでactiveの画像を取り出し、残りの画像で配列を作成
			let idx = 0;
			$lists.forEach( ($li, index) => {
				if( $li.classList.contains("active") ){
					idx = index;
				}
			});
			let rowIndex = grid.current - grid.colNum*grid.row;
			/* 現在地から-1したものをunshiftで配列に足していく*/
			let nMinus = idx;
			for( let i = rowIndex; i > -1; i--){ //grid.currentの回数分まわす
				nMinus = nMinus < 0 ? len-1: nMinus;
				//imgLists.push($lists[nMinus]);
				imgLists.unshift($lists[nMinus]);
				nMinus--;
			}
			//current 〜 len
			let nPlus = idx+1;
			for(let j = imgLists.length; j < maxCol; j++){
				nPlus = nPlus > len-1 ? 0 : nPlus;
				//console.log(nPlus, j, maxCol);
				imgLists.push($lists[nPlus]);
				nPlus++;
			}
		}
		return imgLists;
	},
	copyImages: function($dom, grid){
		let $listsUL 	= $dom.querySelector("ul.imgs");
		let $lists 		= $listsUL.querySelectorAll("ul.imgs li");
		//console.log($lists);
		let imgHTML = "";
		//let len 	= $lists.length;
		let maxCol 	= 5;
		let safariH = 0;
		if(works.isSafari){
			safariH = document.querySelectorAll("#list_items ul.list_items li.item")[0].clientHeight;
			if(safariH === 0){
				safariH = document.querySelectorAll("[data-list-related] ul li.item")[0].clientHeight;
			}
		}
		//text
		let imgLists 	= works.makeImageArray(grid, maxCol, $lists);
		//作成した配列からhover画像を作成
		//let rowIndex = grid.current - grid.colNum*grid.row;
		let listUL 	= null;
		//let ulStyle = "";
		if( $html.classList.contains("show_works") ){
			listUL 	= document.querySelectorAll("[data-list-related] li.item")[0];
		}else{
			listUL 	= document.querySelectorAll("#list_items ul.list_items li.item")[0];
		}
		let ulStyle = window.getComputedStyle(listUL);
		let w =	listUL.getBoundingClientRect().width + parseFloat( ulStyle.getPropertyValue("margin-right").replace("px", "") );
		//console.log(listUL.clientWidth, w);
		for(let j = 0; j < maxCol; j++){
			let leftPos = works.hoverInfo.left - (w*j +  listUL.getBoundingClientRect().left);
			//let cls = j === rowIndex ? "active": "";
			let img = `<li class="${imgLists[j].classList}" style="transform: translate3d(${leftPos}px, 0, 0);">${imgLists[j].innerHTML}</li>`;
			if(works.isSafari){
				img = `<li style="height: ${safariH}px; transform: translate3d(${leftPos}px, 0, 0);">${imgLists[j].innerHTML}</li>`
			}
			imgHTML += img;
		}
		
		works.hoverView.querySelector(".view_imgs ul").innerHTML = imgHTML;
		works.hoverView.querySelector(".view_inner").style.backgroundColor = $dom.getAttribute("data-color");
		//hoverした時に下にいる要素を消す
		hideOtherBlock();
		function hideOtherBlock(){
			//console.log(grid)
			let lists = document.querySelectorAll("[data-list-items] ul li.item");
			if( $html.classList.contains("show_works") ){
				lists = document.querySelectorAll("[data-list-related] ul li.item");
			}
			
			let start 	= grid.colNum*grid.row;
			let end 	= start + grid.colNum;
			if( end > lists.length){
				end = lists.length;
			}
			//console.log("add hide_img")
			for(let i = start; i < end; i++){
				let li = lists[i];
				if(i != grid.current){
					if( !li.classList.contains("hide_img") ){
						li.classList.add("hide_img");
					}
				}
			}
		}
	},
	getBlockPos: function($dom){
		let posY = $dom.getBoundingClientRect().top + window.pageYOffset;
		//console.log(posY);
		return posY;
	},
	cloneText: function(text){
		let textHTML = "";
		let len = 4;
		for(let i = 0; i < len; i++){
			textHTML += `<span>${text}</span>`;
		}
		works.hoverView.querySelector("h3").innerHTML = "";
		works.hoverView.querySelector("h3").insertAdjacentHTML('beforeend', textHTML);
		works.textMarquee();
	},
	textMarquee: function(element){
		let timer;
		let texts = works.hoverView.querySelectorAll("h3 span");
		let text = works.hoverView.querySelector("h3");
		let w = texts[0].clientWidth;
		
		
		let x = -w;
		text.style.transform = `translate3d(${x}px, 0, 0)`;
		clearTimeout(timer);
		
		setTimeout(function(){
			update();
		}, 100);
		function update(){
			if(works.hoverView.classList.contains("active")){
				x += 1;
				if( x >= 0 ){
					x = -w;
				}
				text.style.transform = `translate3d(${x}px, 0, 0)`;
				timer = setTimeout(function(){
					update();
				}, 11);
				let $activeBlock = document.querySelectorAll(".list_items li a.hover");
				if( $html.classList.contains("show_works") ){
					$activeBlock = document.querySelectorAll("[data-list-related] ul li.item");
				}
				
				if($activeBlock.length === 0){
					works.hoverView.classList.remove("active");
					works.returnHideImage();
					worksSlide.isHover = false;
				}
			}else{
				clearTimeout(timer);
			}
		}
	}
}

export let worksFrame = {
	nextPage: "",
	prevPage: "",
	getSlideshowURL: function(e){
		e.preventDefault();
		let $slideBlock = document.querySelector("[data-slideshow]");
		if( $slideBlock.classList.contains("moving") ){
			return false
		}else{
			if(works.scrollTop != window.pageYOffset){
				
			}else{
				let path = "";
				let dataURL = this.getAttribute("data-url");
				//console.log(dataURL);
				if(dataURL){
					path = dataURL;
				}else{
					path = this.getAttribute("href");
				}
				worksFrame.nextPage = path;
				
				worksFrame.changeURL(path);
				worksFrame.detailNav();
			}
		}
	},
	getURL: function(e){
		e.preventDefault();
		works.isClicked = true;
		let $t = this;
		if( $html.classList.contains("mobile") ){
			if(window.pageYOffset != works.scrollTop){
				if( $t.classList.contains("hover") ){
					works.hoverView.style.top = "";
					$t.classList.remove("hover");
					works.hoverView.classList.remove("active");
					worksSlide.isHover = false;
				}
			}else{
				works.returnHideImage();
				worksFrame.nextPage = "";
				
				let dataURL = $t.getAttribute("data-url");
				if(dataURL){
					worksFrame.nextPage = dataURL;
				}else{
					worksFrame.nextPage = $t.getAttribute("href");
				}
				worksFrame.changeURL(worksFrame.nextPage);
				worksFrame.detailNav();
			}
		}else{
			works.returnHideImage();
			worksFrame.nextPage = "";
			
			let dataURL = $t.getAttribute("data-url");
			if(dataURL){
				worksFrame.nextPage = dataURL;
			}else{
				worksFrame.nextPage = $t.getAttribute("href");
			}
			
			//histories.push(worksFrame.nextPage);
			worksFrame.changeURL(worksFrame.nextPage);
			worksFrame.detailNav();
		}
	},
	backToTopPage: function(e){
		e.preventDefault();
		let path = "/";
		worksFrame.nextPage = path;
		worksFrame.changeURL(path);
	},
	changeURL: function(path){
		window.history.pushState(null, null, path);
		
		worksFrame.checkURL();
	},
	checkURL: function(){
		let info = loc.all();
		let folder = info.pathArry[1];
		//console.log(info, folder, info.pathArry[2])
		if(info.pathLen >= 4 && info.pathArry[info.pathLen-1] === ""){
			if( $html.classList.contains("show_article") ){
				
			}else{
				works.openedPosition = window.pageYOffset;
			}
			worksFrame.changeContent().then( ()=> {
				worksFrame.openPage();
			});
		}else{
			if(folder === ""){
				if( $html.classList.contains("active_article") ){
					worksFrame.closePage();
				}
			}
		}
	},
	changeContent: function(){
		let $article = document.querySelector("[data-article]");
		return new Promise((resolve, reject) => {
			if( $html.classList.contains("show_works") ){ //すでに詳細ページにいたら
				$article.classList.add("disable");
			}
			fetch( worksFrame.nextPage ).then(response=> response.text())
			.then((text) => {
				const parser 		= new DOMParser();
				let loadedContent 	= parser.parseFromString(text, "text/html"),
					ttl 			= loadedContent.title, //ページタイトル
					$content 		= document.querySelector("[data-article] article"),	//書き換えるDOM
					content 		= loadedContent.querySelector("[data-article] .article_inner"); //ロードしたコンテンツの中身
				
				let currentContent = document.querySelector("[data-article] article");
				currentContent.innerHTML = "";
				
				$content.appendChild(content);
				//$content.appendChild(content);
				document.title = ttl; 
				let imgs = document.querySelectorAll("[data-article] #kv_project picture source");
				let imgArry = [];
				
				imgs.forEach( (img　) => {
					let path = img.getAttribute("srcset");
					if(path != ""){
						imgArry.push( path );
					}
				});
				//console.log(imgArry);
				if(imgArry.length > 0){
					preload.startLoad(imgArry).then( res => {
						resolve();
					});
				}else{
					resolve();
				}
			});
		});
	},
	openPage: function(){
		//scrollingがつくと変な動きをするので制御
		if( $html.classList.contains("show_works") ){ //すでに詳細ページにいたら
			window.scrollTo(0, 0);
			worksFrame.detailNav();
			works.nav();
			//worksFrame.checkFvVideo();
			document.querySelector("[data-article]").classList.remove("disable");
			worksMovies.init();
			works.isClicked = false;
		}else{
			$html.classList.remove("scrolling");
			$html.classList.add("active_article");
			
			setTimeout(function(){
				$html.classList.add("show_article");
				$html.classList.add("hide_list");
				works.hoverView.style.top = "";
				setTimeout(function(){
					$html.classList.add("show_works");
					worksFrame.removeHover();
					window.scrollTo(0, 0);
					setTimeout(function(){
						worksFrame.detailNav();
						works.nav();
						common.beforeType = "";
					
						setTimeout(function(){
							symbol.init("workdetail");
							works.isClicked = false;
							worksMovies.init();
							common.checkVideo();
						}, 200);
					}, 400);
				}, 50);
			}, 30);
		}
	},
	closePage: function(){
		$html.classList.remove("show_works");
		setTimeout(function(){
			$html.classList.remove("hide_list");
			document.title = document.querySelector('meta[property="og:site_name"]').content;;
			//hoverで隠れていたサムネを表示する
			works.returnHideImage();
			
			if(kv.slides.length === 0){
				kv.init();
			}
			setTimeout(function(){
				//console.log(works.openedPosition);
				window.scrollTo(0, works.openedPosition);
				$html.classList.remove("show_article");
				setTimeout(function(){
					$html.classList.remove("active_article");
					document.querySelector(".article_content").innerHTML = "";
					symbol.init("top");
					histories = [];
					works.isClicked = false;
					worksSlide.isHover = false;
					let $list_imgs = document.querySelectorAll("li.item.hide_img");
					
					$list_imgs.forEach( ($list) => {
						$list.classList.remove("hide_img")
					});
				}, 50);
			}, 50);
		}, 100);
	},
	detailNav: function(){
		let $closes = document.querySelectorAll("[data-article] a[data-close]");
		$closes.forEach( ($close ) => {
			if( $html.classList.contains("mobile") ){
				$close.removeEventListener("touchend", worksFrame.backToTopPage, { passive: false, capture: false});
				$close.addEventListener("touchend", worksFrame.backToTopPage, { passive: false, capture: false});
			}else{
				$close.removeEventListener("click", worksFrame.backToTopPage, { passive: false, capture: false});
				$close.addEventListener("click", worksFrame.backToTopPage, { passive: false, capture: false});
			}
		});
		const observer = lozad();
		observer.observe();
		worksFrame.checkColumn();
	},
	removeHover: function(){
		let $lists = document.querySelectorAll("[data-list-items] ul li.item a");
		$lists.forEach( ($list ) => {
			if( $list.classList.contains("hover") ){
				$list.classList.remove("hover");
			}
		});
		if(works.hoverView.classList.contains("active")){
			works.hoverView.classList.remove("active");
		}
	},
	checkColumn: function(){
		let $sectionBody = document.querySelectorAll("section.body");
		$sectionBody.forEach( ($area) => {
			addCol3Class($area);
		});
		
		let $freearea = document.querySelectorAll(".block_freearea");
		$freearea.forEach( ($area) => {
			addCol3Class($area);
		});
		function addCol3Class($dom){
			let $cols = $dom.querySelectorAll("[data-template]");
			let before = false;
			let count = 0;
			$cols.forEach( ($col, index) => {
				let current = false;
				if( $col.classList.contains("col3") ){
					current = true;
					if(before){
						count++;
						if(index != 0){
							let cls = "";
							if(count%2 === 0){
								cls = "col3_odd";
							}else{
								cls = "col3_even";
							}
							$col.classList.add(cls);
						}
					}
				}else{
					current = false;
					count = 0;
				}
				before = current;
			});
		}
	}
}