import { object } from './_obj.js';
import { load } from './_load.js';
const VimeoOptions = {
	byline: false, // 投稿者部分の表示
	portrait: false, // 左上のVimeoのマークの表示
	title: false // タイトルの表示
}

var tag = document.createElement('script');
tag.src = "//www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
window.onYouTubeIframeAPIReady = function() {
	worksMovies.ytPlayerReady = true;
}
let $html = document.documentElement;

let yTPlayer = {
	player: null,
	count: 0,
	dom: null,
	isAuto: false,
	setYouTube: function(){
		let $this = this;
		let $player 	= this.dom.querySelector(".ytPlayer");
		let $btnPlay 	= this.dom.querySelector("a.btn_play_movie");
		let movieID 	= this.dom.getAttribute("data-movie-id");
		let hasControl 	= 1;
		if(this.isAuto){
			movieID = $player.getAttribute("data-yt-id");
			hasControl = 0;
		}
		let id 	= $player.getAttribute("id");
		
		//console.log(this.player, this.dom, this.isAuto, movieID, id);
		$this.player = new YT.Player(id, {
			width: $player.getAttribute("width"),
			height: $player.getAttribute("height"),
			videoId: movieID,
			playerVars: {
				autohide	: 1,
				controls	: hasControl,
				frameborder : 0,
				rel			: 0,
				start		: 0,
				showinfo 	: 0,
				wmode		: "transparent",
				autoplay	: 0,
				mute 		: 1,
				playsinline	: 1,
				enablejsapi	: 1,
				iv_load_policy: 3,
				origin: location.protocol + '//' + location.hostname + "/"
			},
			events: {
				'onReady': function(){
					//console.log("is ready", $this.isAuto, id, movieID, $player);
					if( !$this.isAuto ){
						if( $html.classList.contains("mobile") ){
							$btnPlay.removeEventListener("touchend",playMovie, false);
							$btnPlay.addEventListener("touchend", playMovie, false);
						}else{
							$btnPlay.removeEventListener("click",playMovie, false);
							$btnPlay.addEventListener("click", playMovie, false);
						}
					}else{
						console.log(id);
						document.querySelector("#" + id).classList.add("autoplay");
						playMovie();
					}
				},
				'onStateChange': function(e){
					//console.log($this.player, $this.dom, e.data);
					if(e.data === 1){
						if( !$this.dom.classList.contains("playing") ){
							$this.dom.classList.add("playing");
						}
					}else if(e.data === 0){
						playMovie();
					}
				}
			}
		});
		function playMovie(){
			//console.log($this.player, $this.dom, "play");
			$this.player.seekTo(0);
			$this.player.playVideo();
		}
	}
}
export let worksMovies = {
	fv: null,
	ytPlayers: [],
	ytPlayerReady: false,
	init: function(){
		let ytPlayers = document.querySelectorAll(`[data-movie-type="youtube"]`);
		ytPlayers.forEach( ($player, index) => {
			let player = $player.querySelector(".ytPlayer");
			player.setAttribute("id", `yt_${index}`);
		});
		if( document.querySelector("#kv_project") ){
			checkYT();
		}
		
		function checkYT(){
			if(worksMovies.ytPlayerReady){
				let type = document.querySelector("#kv_project").getAttribute("data-type");
				worksMovies.autoPlayFv(type);
				worksMovies.checkAutoPlay();
			}else{
				setTimeout(function(){
					checkYT();
				}, 33);
			}
		}
		/*
		let $movies = document.querySelectorAll("[data-video-pc]");
		console.log($movies);
		if($movies.length > 0){
			$movies.forEach( ($movie) => {
				let src = $movie.getAttribute("data-video-sp");
				if( $html.classList.contains("pc") ){
					src = $movie.getAttribute("data-video-pc");
				}
				$movie.querySelector("video source").setAttribute("src", src);
				console.log(src)
			});
		}
		*/
	},
	autoPlayFv: function(type){
		let $kv = document.querySelector("#kv_project");
		if(type === "video"){
			worksMovies.fv = $kv.querySelector("video");
		}else if(type === "embed"){
			//worksMovies.fv = new Vimeo.Player( $kv.querySelector("iframe"), options );
			
		}
	},
	checkAutoPlay: function(){
		let movies = document.querySelectorAll("article [data-movie-type]");
		worksMovies.ytPlayers	= [];
		let count = 0;
		let len = movies.length;
		let ytCount = 0;
		
		checkMovie();
		
		function checkMovie(){
			if(count < len){
				let $thisMovie = movies[count];
				let type = $thisMovie.getAttribute("data-movie-type");
				let id = $thisMovie.getAttribute("data-movie-id");
				
				//console.log($thisMovie, count, len, type);
				if(type === "vimeo"){
					if(id){ //click and play
						//画像をvimeoから取得
						getPoster($thisMovie, type);
					}else{ //autoplay
						worksMovies.autoPlayVimeo($thisMovie);
					}
				}else{ //youtube
					let isAuto = false;
					if(id){ //click and play
						//画像をyoutubeから取得
						getPoster($thisMovie, type);
					}else{ //autoplay
						isAuto = true;
					}
					let _player = object(yTPlayer);
					_player.count 	= ytCount;
					_player.dom 	= $thisMovie;
					_player.isAuto 	= isAuto;
					_player.setYouTube();
					worksMovies.ytPlayers.push(_player);
					ytCount++;
				}
				count++;
				checkMovie();
			}
		}
		function getPoster($dom, type){
			let id = $dom.getAttribute("data-movie-id");
			if(type === "vimeo"){
				let url = "https://vimeo.com/api/oembed.json?url=https://vimeo.com/" + id;
				//console.log(url);
				load.getJson(url, {}).then( (res) => {
					let img = res.thumbnail_url;
					if(img.indexOf("_") > -1){
						img = img.split("_")[0];
					}
					//console.log($dom, res.thumbnail_url);
					if( res.thumbnail_url === "" ){
						//$dom.querySelector(".btn_play_movie img").remove;
					}else{
						$dom.querySelector(".btn_play_movie").insertAdjacentHTML("afterbegin", `<img src="${img}" alt="Play Movie">`);
						//$dom.querySelector(".btn_play_movie img").setAttribute("src", img);
					}
					
					let $playBtn = $dom.querySelector("a.btn_play_movie");
					worksMovies.vimeoNav($playBtn, $dom);
				});
			}else{
				let idArry 	= id.split("?");
				let movieID = idArry[0];
				let src = `https://img.youtube.com/vi/${movieID}/maxresdefault.jpg`;
				$dom.querySelector("img").setAttribute("src", src);
			}
		}
	},
	vimeoNav: function($btn, $dom){
		if( $html.classList.contains("mobile") ){
			$btn.removeEventListener("touchend", {
				dom: $dom,
				handleEvent: worksMovies.playVimeo
			}, false);
			$btn.addEventListener("touchend", {
				dom: $dom,
				handleEvent: worksMovies.playVimeo
			}, false);
		}else{
			$btn.removeEventListener("click", {
				dom: $dom,
				handleEvent: worksMovies.playVimeo
			}, false);
			$btn.addEventListener("click", {
				dom: $dom,
				handleEvent: worksMovies.playVimeo
			}, false);
		}
		
	},
	autoPlayVimeo: function($dom){
		let $iframe = $dom.querySelector("iframe");
		let $embedPlayer 	= new Vimeo.Player( $iframe, VimeoOptions );
		$embedPlayer.play().then( () => {
			if( !$dom.classList.contains("playing") ){
				$dom.classList.add("playing");
			}
		});
	},
	playVimeo: function(){
		let $iframe = this.dom.querySelector("iframe");
		
		let $embedPlayer = new Vimeo.Player( $iframe, VimeoOptions );
		let $cover = this.dom;
		$embedPlayer.play().then( () => {
			if( !$cover.classList.contains("playing") ){
				$cover.classList.add("playing");
			}
		});
	}
}