import { object } from './_obj.js';
let Slide = {
	count: 0,
	max: 0,
	target: null,
	isMovie: "",
	switchTime: 0,
	startTime: 0
}
export let worksSlide = {
	slides: [],
	start: 0,
	next: 0,
	duration: 2000,
	isHover: false,
	init: function(){
		worksSlide.addToList();
		if(worksSlide.slides.length > 0){
			worksSlide.startSlide();
		}
	},
	addToList: function(){
		//loadedクラスがついているリストだけ登録する
		let $lists = document.querySelectorAll("[data-list-items] ul li.loaded");
		$lists.forEach( ($list) => {
			if( $list.querySelectorAll("ul.imgs li").length > 1 ){
				let _s = object(Slide);
				_s.count 	= 0;
				_s.max 		= $list.querySelectorAll("ul.imgs li").length;
				_s.target 	= $list.querySelector("ul.imgs");
				_s.switchTime 	= Math.floor( Math.random()*500 ) + 1000;
				//console.log(_s.switchTime);
				_s.startTime 	= worksSlide.getNow();
				$list.classList.remove("loaded");
				worksSlide.slides.push(_s);
			}else{
				$list.classList.remove("loaded");
			}
		});
		//console.log(worksSlide.slides);
	},
	startSlide: function(){
		//console.log("works Slideshow: ", worksSlide.slides, worksSlide.start);
		worksSlide.check();
	},
	check: function(){
		let duration = 100;
		//console.log(worksSlide.isHover);
		if(worksSlide.isHover){
			for(let i = 0; i < worksSlide.slides.length; i++){
				let s = worksSlide.slides[i];
				s.startTime += duration;
			}
		}else{
			let current = worksSlide.getNow();
			for(let i = 0; i < worksSlide.slides.length; i++){
				let s = worksSlide.slides[i];
				let diff = current - s.startTime;
				//console.log(diff, current, s.startTime, s.switchTime);
				if(diff >= s.switchTime){
					s.count++;
					if(s.count >= s.max){
						s.count = 0;
					}
					s.startTime = worksSlide.getNow();
					worksSlide.change(s);
				}
			}
		}
		
		setTimeout(function(){
			worksSlide.check();
		}, duration);
	},
	change: function(s){
		let $lists = s.target.querySelectorAll("li");
		//console.log(s.count, s.max);
		$lists.forEach( ($list, index) => {
			if(index === s.count){
				$list.classList.add("active");
			}else{
				if( $list.classList.contains("active") ){
					$list.classList.remove("active");
				}
			}
		});
	},
	getNow: function(){
		let now = new Date();
		return now.getTime();
	}
}